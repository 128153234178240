
// import React, { Children }   from "react"
// import { Navigate, useNavigate } from "react-router-dom"
// import { getCmpId } from "./utilities"
// import { getLvl, getWho } from "./cpcb"

// type Props = {
//     children :JSX.Element
// }


//  export const NrjRequire  = ({children}:Props) : JSX.Element =>{
//     const showPageNotFound = <><h4 className="m-3 p-4 text-center font-semibold text-lg"> Page Not Found</h4></>;
//     const protectedRoutes: string[] = ["/changePwdRgd", "/changePwdSpcb"]
    
//     let cmpid:string = getCmpId();
//     let lvl:string = getLvl();
//     let who = getWho();
//     return children
//     if (cmpid && who){
//         let cmp : string =cmpid
//         if (cmp.length > 4){
//             let foundProtectedRoute:boolean = false;
//             for(let i = 0; i < protectedRoutes.length; i++){
//                 if(window.location.pathname == protectedRoutes[i] && lvl != 'CPCB'){
//                     foundProtectedRoute = true;
//                     break;
//                 }
//             }
//             if(foundProtectedRoute){
//                 <Navigate to ="/login"></Navigate>
//             }
            
//             return children;
//         }
//     }
//     return <Navigate to ="/login"></Navigate>
// }

import React, { ReactNode } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { getCmpId } from "./utilities";
import { getLvl, getWho } from "./cpcb";

type Props = {
    children: ReactNode;
};

export const NrjRequire = ({ children }: Props): JSX.Element => {
    const location = useLocation();
    const protectedRoutesRd: string[] = ["/changePwdRgd"];
    const protectedRoutesSpcb: string[] = ["/changePwdSpcb"];
    const protectedRoutesHcf: string[] = ['/hcfDetails'];
    
    const cmpid: string | null = getCmpId();
    const lvl: string | null = getLvl();
    const who = getWho();
    if (!cmpid || !who) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }
    const isProtectedRouteRd = protectedRoutesRd.includes(location.pathname);
    const isProtectedRouteSpcb = protectedRoutesSpcb.includes(location.pathname);
    const isProtectedRouteHcf = protectedRoutesHcf.includes(location.pathname);

    if (isProtectedRouteRd  && lvl !== "CPCB") {
        return <Navigate to="/login" state={{ from: location }} replace />;
    } 
    if (isProtectedRouteSpcb && lvl !== "CPCB") {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    if (isProtectedRouteHcf && lvl !== "HCF") {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }
    return <>{children}</>;
};
